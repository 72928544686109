import React from 'react';
import './BatteryStatus.css';

class BatteryStatus extends React.Component {

  state = {
    isStale: false
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.startTimer();
  }

  startTimer() {
    console.log("startTimer: starting timer");
    clearInterval(this.interval);
    this.interval = setInterval(() => this.onIntervalElapsed(), 10000);    
  }

  componentWillUnmount() {
    console.log("componentWillUnmount: clearing timer");
    clearInterval(this.interval);
  }

  onIntervalElapsed() {
    console.log("onIntervalElapsed");
    if (! this.state.isStale) {
      console.log("onIntervalElapsed: setting isStale flag on battery status");
      this.setState({isStale: true});      
    }
  }

  /** Invoked from the parent component to let us know that battery state has changed. **/
  updateBatteryState(newBatteryState) {
    console.log("updateBatteryState: batteryState changed to %o", this.state.batteryState);
    this.setState({batteryState: newBatteryState});
    this.setState({isStale: false});
    this.setState({isUpdated: true});
    setTimeout(() => this.setState({isUpdated: false}), 500);
    this.startTimer();
  }

  render() {
    let divClass = this.state.isStale ? "staleStatus" : this.state.isUpdated ? "updated" : "liveStatus";
    return (
      <div id="BatteryStatus" className={divClass}>
        <h3>Battery Status</h3>
        <p>Voltage: {`${ this.getStatus("voltage") } volts`}</p>
        <p>Charge ratio: {`${ this.getStatus("charge_ratio") }`}</p>
        <p>Current: {`${ this.getStatus("current") }`}</p>
        <p>Temperature: {`${ this.getStatus("temperature") }`}</p>
        <p>Charging state: {`${ this.getStatus("charging_state") }`}</p>
        <p>Mode: {`${ this.getStatus("mode") }`}</p>
      </div>
    ); 
  }

  getStatus(fieldName) {
    if (this.state.batteryState === undefined) {
      return "---";
    } else {
      let value = this.state.batteryState[fieldName];
      return (typeof(value) == "number") ? value.toFixed(2) : value;
    }
  }     

}

export default BatteryStatus;