import React from 'react';
import './App.css';
import BatteryStatus from './BatteryStatus';
import LoginComponent from './LoginComponent';
import ManualDriveControls from './ManualDriveControls';

const io = require('socket.io-client');

class App extends React.Component {

  constructor(props) {
    super(props);
    this.sendMessage = this.sendMessage.bind(this);
    this.batteryStatusComponent = React.createRef();
  }

  state = {
    isLoggedIn: false,
    voltage: undefined,
    socket: null,
    apiKey: null,
    loginError: null
  }

  componentDidMount() {
    document.title = "Daffodil Web Portal"
  }

  render() {
    if (this.state.loginError) {
      return (
        <div className="App">
          <p>{this.state.loginError}</p>
        </div>
      );
    } else if (this.state.isLoggedIn) {
      return (
        <div className="App">
            <div>
              <ManualDriveControls sendMessage={this.sendMessage} />
              <BatteryStatus ref={this.batteryStatusComponent} />
            </div>
        </div>
      );
    } else {
      return (
        <div className="App">
            <div>
              <h1>Please log in</h1>
              <LoginComponent authServerUrl={this.props.authServerUrl} onLoginSuccess={x => this.onLoginSuccess(x) } />
            </div>
        </div>
      );
    }
  }

  onLoginSuccess(apiKey) {
    console.log("Login success");
    this.setState({isLoggedIn: true, apiKey: apiKey});
    console.log(`Connecting to message relay at ${this.props.messageRelayUrl}`);
    const socket = io.connect(this.props.messageRelayUrl);

    socket.on('connect', () => {
      console.log("Connected to message relay");
      this.setState({socket: socket});
      this.sendMessage("subscribe", "battery_state", null);
      console.log("Subscribed to battery messages");
    });

    socket.on('connect_error', (error) => {
      console.error("Error establishing connection to message relay", error);
      this.setState({loginError: "Error establishing connection to message relay"});
    });

    socket.on('message', (payload) => {
      console.log("Received message with payload: %o", payload);
      if (payload.topic === "battery_state") {
        console.log("Updating state of batteryComponent with new payload data");
        this.batteryStatusComponent.current.updateBatteryState(payload.data);
      }
    });
  }

  sendMessage(command, topic, data) {
    if (this.state.socket != null) {
      console.log(`Sending command '${command}'' on topic '${topic}' with data: %o`, data); 
      this.state.socket.emit("message", {"command": command, "api_key": this.state.apiKey, "topic": topic, "data": data});
    } else {
      console.log("sendData: socket not yet connected");
    }
  }
}

export default App;
