import React from 'react';

class LoginComponent extends React.Component {
  state = {loginError: null}

  render() {
    return(
      <div>
        { this.state.loginError && <p>Error during login: {this.state.loginError}</p>  }
        <p><label>Name</label><input name="username" type="text" onChange={this.changeHandler} /></p>
        <p><label>Password</label><input name="password" type="password" onChange={this.changeHandler} /></p>
        <button onClick={() => this.doLogin()}>Login</button>
      </div>
    )
  }

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;  
    console.log(`Updating field ${name}`);
    this.setState({ [name]: value });
  }

  doLogin() {
    console.log(`Sending login request to server at ${this.props.authServerUrl}`);
    let data = {username: this.state.username, password: this.state.password};
    fetch(`${this.props.authServerUrl}/login`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
      })
      .then((result) => this.parseLoginResponse(result),
            (error) => this.handleLoginError(error));
  }

  parseLoginResponse(result) {
    console.log(`Raw result:`);
    console.log(result);
    if (result.ok) {
      result.json().then(data => {
        if ("api_key" in data) {
          this.props.onLoginSuccess(data["api_key"]);      
        } else {
          console.log("api_key not in response, showing login error");
          this.setState({ loginError: "invalid username/password" });    
        }
      });
    } else {
      console.log("Login request returned error.");
      this.setState({ loginError: "unknown error" });
    }
  }

  handleLoginError(error) {
    console.log("Error when calling /login endpoint");
    console.log(error);
    this.setState({loginError: error.toString()})
  }
}

export default LoginComponent;
