import React from 'react';
import './ManualDriveControls.css';

/** Prevent context menu during long click on mobile devices. **/
window.oncontextmenu = function(event) {
   event.preventDefault();
   event.stopPropagation();
   return false;
};


class ManualDriveControls extends React.Component {

  constructor(props) {
    super(props);
    this.startCommand = this.startCommand.bind(this);
    this.endCommand = this.endCommand.bind(this);
  }

  state = {
    activeCommand: null
  }

  sendCommand(command) {
    console.log(`Sending command: ${command}`);
    this.props.sendMessage("publish", "drive_commands", {"direction": command});
  }

  startCommand(command) {
    console.log("Starting command sequence: %s", command);
    this.setState({activeCommand: command})
    clearInterval(this.interval);
    this.interval = setInterval(() => this.onIntervalElapsed(), 200);    
  }

  onIntervalElapsed() {
    console.log("onIntervalElapsed");
    this.sendCommand(this.state.activeCommand);
  }

  endCommand() {
    console.log("Ending command sequence");
    clearInterval(this.interval);
    this.setState({activeCommand: null});
  }

  render() {
    return (
      <div id="driveButtonDiv">
        <button id="forwardButton" 
                className="driveButton noSelect" 
                onMouseDown={() => this.startCommand("forward")}
                onTouchStart={() => this.startCommand("forward")}
                onMouseUp={this.endCommand} 
                onTouchEnd={this.endCommand} 
         >FWD</button>
        <div>
          <button id="ccwButton" 
                  className="driveButton noSelect" 
                  onMouseDown={() => this.startCommand("ccw")}
                  onTouchStart={() => this.startCommand("ccw")}
                  onMouseUp={this.endCommand}
                  onTouchEnd={this.endCommand}
          >CCW</button>
          <button id="cwButton" 
                  className="driveButton noSelect" 
                  onMouseDown={() => this.startCommand("cw")}
                  onTouchStart={() => this.startCommand("cw")}
                  onMouseUp={this.endCommand} 
                  onTouchEnd={this.endCommand}
           >CW</button>
        </div>
        <button id="reverseButton" 
                className="driveButton noSelect" 
                onMouseDown={() => this.startCommand("reverse")}
                onTouchStart={() => this.startCommand("reverse")}
                onMouseUp={this.endCommand} 
                onTouchEnd={this.endCommand}
         >REV</button>
        <div>
          <button id="dockButton" className="driveButton noSelect" onClick={() => this.sendCommand("dock")} >Dock</button>
          <button id="undockButton" className="driveButton noSelect" onClick={() => this.sendCommand("undock")} >Undock</button>
        </div>
      </div>
    )
  }
}

export default ManualDriveControls;